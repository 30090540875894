import React from 'react'
import styles from './Jumbotron.module.scss'
import Jump from 'react-reveal/Jump'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Jumbotron = props => {
  const { title, description, icon } = props
  return (<div className={['jumbotron jumbotron-fluid', styles.container].join(' ')}>
    <div className="container">
      <Jump>
        <h1 className="display-4"><FontAwesomeIcon icon={icon}/> {title}</h1>
        <p className="lead">{description}</p>
      </Jump>
    </div>
  </div>)
}

Jumbotron.propTypes = {
  description: PropTypes.any,
  title: PropTypes.any,
  icon: PropTypes.any
}

export default Jumbotron

import React from 'react'
import PropTypes from 'prop-types'
import styles from './Section1.module.scss'
import { Fade } from 'react-reveal'

const Section1 = props => {
  const { className, children, id } = props
  return (<div id={id} className={[className, styles.container].join(' ')}>
    <div className={['row text-center', styles.centered].join(' ')}>
      <div className='col-md-12'>
        <Fade>
          {children}
        </Fade>
      </div>
    </div>
  </div>)
}

Section1.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
  title: PropTypes.any,
  id: PropTypes.string
}

export default Section1
